import { waitAuth } from "~/utils/middleware";

import { Sub } from "~/types/subscriptions";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth(Sub.Type.Drive);

    if (__subscription().has(Sub.Type.Drive)) {
        log("❌ Has Drive");
        return navigateTo("/");
    }

    log("✅ Has no Drive");
});
